import React from "react";
import { Link } from "gatsby";

import "../components/globalStyle.css";
import "../components/aboutMe.css";
import { withPrefix } from "gatsby";

import Header from "../components/header.js";

const AboutMe = () => (
  // render() {
  //   return (
  //     <a
  //       rel="noopener noreferrer"
  //       href={withPrefix('/static/CelineParkResume.pdf')}
  //     target="_blank">
  //       MY RESUME
  //     </a>
  //   )
  // }
  <div className="aboutMeGroup">
    <Header />

    <div className="aboutMeContent">
      <div className="aboutMeTextTitle">
        <h2> Hi! I'm Celine, a product designer. </h2>
      </div>

      <div className="aboutMeText">
        <p>
          <br />I have a background in architecture (if you'd like to see that
          work, reach out to me). But in 2017, I started a career in product
          design. In that time I've had the opportunity to work on a number of
          complex problems, and more recently, build a pretty fire design
          system.
          <br />
          {/* in my free time i like making things out of paper, clay, and food. I also enjoy dog watching in the park. */}
        </p>

        <br />
        <br />
        <hr />
      </div>

      <div className="contact">
        <div className="contactTitle">
          <img src={require("../img/link.png")} className="linkImg" />
        </div>

        <a href="mailto:thisiscelinepark@gmail.com">
          <img src={require("../img/gmail.svg")} className="socialIcon" />
        </a>
        <a href="https://www.linkedin.com/in/celineparkdesign" target="_blank">
          <img src={require("../img/linkedin.svg")} className="socialIcon" />
        </a>
        <a href="/CelineParkResume.pdf" target="_blank">
          <p className="resumeDownload">
            <b>MY RESUME</b>
          </p>
        </a>
      </div>
    </div>
  </div>
);

export default AboutMe;
